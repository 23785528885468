<template>
  <div class="overflow-hidden sm:overflow-x-hidden">
    <div class="min-h-screen">

      <div class="relative h-screen min-h-[400px] max-h-[470px] sm:max-h-[800px] lg:max-h-[900px] xl:max-h-[1000px]">
        <div class="absolute inset-0 bg-black opacity-60"></div>
        <img src="../assets/Hanga-24/home/insta-4.jpg" alt="Background" class="h-full w-full object-cover">
        
        <NavIgnite class="absolute top-0 left-0 w-full"/>
      
        <div class="space-y-16">
        <div class="font-rockinsoda absolute  text-white text-3xl sm:text-4xl md:text-5xl lg:text-7xl xl:text-7xl  top-32 lg:top-52 left-4 sm:left-8 md:left-12 lg:left-16 xl:left-20 xl:2/4 text-center sm:text-left" ref="text">
          <p v-for="(line, index) in heroText" :key="index" class="hero-text" :style="{ animationDelay: `${index * 0.8}s` }">
            {{ line }}
          </p>
        </div>

        <div class="absolute top-52 lg:top-1/2  left-4 sm:left-8 md:left-12 lg:left-16 xl:left-20 xl:2/4 sm:text-left">
         
          <p v-for="(line, index) in baseText" :key="index" class="hero-text font-gramatika text-white text-md lg:text-2xl" :style="{ animationDelay: `${index * 0.8}s` }" >
            {{ line }}
          </p>

          <button  @click="showVideo=true" class="hero-button  mt-4 lg:mt-12 py-4 px-8 font-gramatika text-xl    text-white font-semibold bg-jade flex items-center space-x-2 transition-all duration-300">
            <a href="https://hanga.acceleratorapp.co/application/new?program=hanga-venture-ignite" target="_blank"> Apply Here</a>

          </button>

        </div>

        
      </div>

  
  
      </div>

        <!-- second section -->
         <div class=" p-2 lg:p-12">
        
        <div class="ml-4 sm:ml-8 md:ml-0 lg:ml-0 xl:ml-20 mt-10 sm:mt-8 md:mt-12 lg:mt-16 xl:mt-20 space-y-4">
          <h1 class="text-darknavy text-4xl font-bold font-gramatika">Are you a startup at the ideation </h1>
          <h1 class="text-darknavy text-4xl font-bold font-gramatika">or MVP stage ?</h1>
          <p class="text-navy font-gramatika text-sm sm:text-base md:text-lg lg:text-xl  xl:text-xl">
            Hanga Venture Ignite aims to provide essential financial support to early-stage Rwandan startups,
             enabling them to overcome initial capital constraints and kickstart their operations. By targeting startups at the ideation or MVP stage, the window seeks to foster innovation and entrepreneurship. This will facilitate the startups in attracting further investment, 
            accelerating their growth and contributing to the economic development of Rwanda.
          </p>
        
        </div>
        
        

         <!-- third section  -->
         <div class="flex flex-col md:flex-row p-5 md:p-20 space-y-5 md:space-y-0 md:space-x-12">

          <div class="flex-1 h-[300px]  md:h-[70vh] max-w-full md:max-w-[50%]">
            <img src="../assets/nsrh/n-srh-2.jpg" class="w-full h-full object-cover">
          </div>
        
          <div class="flex-1 h-[300px]  md:h-[70vh] max-w-full md:max-w-[50%]">
            <img src="../assets/nsrh/n-srh-1.jpg" class="w-full h-full object-cover">
          </div>
        
        </div>
        
          <!-- section 4 -->

          <div class=" space-y-4 mt-6">
            <p class="text-center text-md text-jade">TWO TIERS </p>
            <div class="text-center font-semibold text-xl text-darknavy">
              <p>Hanga Venture Ignite will disburse grants directly to early-stage startups and will target </p>
              <p>startups at the ideation and Minimum Viable Product (MVP) stage with high growth potential </p>
              <p>into two tiers:</p>
            </div>
          </div>
         


           <!-- section five  -->
            
           <div class="ml-4 mr-4 xl:mt-4 2xl:-mt-12 mlg:mr-10 md:ml-12 flex flex-col md:flex-row lg:space-x-16 items-start justify-between">

            <div class="flex-1 h-[300px] sm:mt-10 md:h-[90vh]  md:mt-10 xl:mt-20 2xl:mt-36 max-w-full md:max-w-[50%]">
              <img src="../assets/idea.jpeg" class="w-full mt-10 h-full  object-cover">
            </div>

            <div class="flex-1  mt-12 xl:mt-2  md:mt-8 max-w-full md:max-w-[50%]">
              <div class="space-y-2  md:mt-28 ">
                <p class="text-lg md:text-lg font-grammatika text-darknavy font-semibold">Tier 1 - Ignite </p>
              </div>
            
              <div class="mt-2">
                <div class="text-justify space-y-4 w-full">

                  <p class="text-navy font-gramatika text-md md:text-lg">
                    This tier is designed to support startups at the ideation stage, where the primary focus is on developing and validating initial ideas and concepts. The grants aim to help startups overcome initial capital constraints, enabling them to progress from a concept to a tangible business proposition. 
                    The expected outcomes for the startups in this grant tier are:
                  </p>
          
                  <p class="text-navy font-gramatika text-lg md:text-md">
                    <span class="font-bold text-darkGray">-Proof of concept:</span> Proof of concept: a validated prototype that effectively addresses a specific market need and demonstrates feasibility, 
                    an initial market validation indicating customer interest and demand for the product or service, and a clear value proposition that differentiates the startup from competitors.
                  </p>

                  <p class="text-navy font-gramatika text-lg md:text-md">
                    <span class="font-bold text-darkGray">-Enhanced team capabilities:</span> strengthened team expertise and readiness to tackle subsequent stages of development,
                     and established mentorship and advisory relationships that provide ongoing support.
                  </p>

                  <p class="text-navy font-gramatika text-lg md:text-md">
                    <span class="font-bold text-darkGray">-Early market engagement: </span> an increase in market awareness and interest in the startup's offerings, and
                     positive feedback from early adopters and potential customers that guides further development.
                  </p>
          
                 
                </div>
              
              </div>
            </div>
           
         
          </div>


          <!-- section 6 -->
          <div class="ml-4 mr-4 xl:mt-20 2xl:mt-20 lg:mr-10 md:ml-12 flex flex-col md:flex-row lg:space-x-16 items-start justify-between">

            

            <div class="flex-1  mt-12 xl:mt-2  md:mt-8 max-w-full md:max-w-[50%]">
              <div class="space-y-2  md:mt-28 ">
                <p class="text-lg md:text-lg font-grammatika font-semibold text-darknavy"> Tier 2 - Ignite + </p>
              </div> 
            
          
              <div class="mt-2">
                <div class="text-justify space-y-4 w-full">

                  <p class="text-navy font-gramatika text-md md:text-lg">
                    This tier targets startups that have developed a Minimum Viable Product (MVP) and are seeking to refine, scale, and enter the market. The grants aim to support these startups in overcoming challenges associated with early market entry and expansion, providing the necessary resources to achieve significant growth.
                     The expected outcomes for the startups in this grant tier are:
                  </p>
          
                  <p class="text-navy font-gramatika text-lg md:text-md">
                    <span class="font-bold text-darkGray">- Market readiness: </span> an enhanced MVP that is fully ready for market entry and capable of meeting customer needs and expectations, successful market launch with initial user acquisition and revenue generation, 
                    and a clear go-to-market strategy that positions the startup for rapid growth.
                  </p>

                  <p class="text-navy font-gramatika text-lg md:text-md">
                    <span class="font-bold text-darkGray">-Business growth and expansion:</span>  an expanded team with the necessary skills and expertise to support ongoing development and operations, established partnerships and
                     pilot projects that validate the product and open avenues for further expansion, and a scalable business model that supports sustainable growth.
                  </p>

                  <p class="text-navy font-gramatika text-lg md:text-md">
                    <span class="font-bold text-darkGray">-Increased investment attractiveness: </span> a well-developed product and initial market traction that increase the startup's attractiveness to potential investors,
                     secured intellectual property and regulatory compliance that mitigate risks and enhance investor confidence, and demonstrated potential for significant return on investment.</p>
          
                 
                </div>
              
              </div>
            </div>

            <div class="flex-1 h-[300px] sm:mt-10 md:h-[100vh]  md:mt-10 xl:mt-20 2xl:mt-36 max-w-full md:max-w-[50%]">
              <img src="../assets/Hanga-24/network/hero-section.jpg" class="w-full mt-10 h-full  object-cover">
            </div>
           
         
          </div>

          <!-- section seven -->
       <div class="mt-20 w-full space-y-10 p-10 ">
      <div>
        <div class="bg-aqua p-8 flex justify-between cursor-pointer" @click="toogleEligibility">
          <p class="font-rockinsoda text-navy text-5xl">REQUIRED DOCUMENTS</p>

          <i v-if="isEligibilityOpen" class=" text-navy fa fa-arrow-down bg-aqua fa-3x" aria-hidden="true"></i>
          
          <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

        </div>
        <div v-if="isEligibilityOpen" class="bg-aqua">
          <p class="text-darknavy font-bold  font-gramatika text-2xl p-6  ml-8"> A. For initial assessment</p>


          <div v-for="(eligible, index) in eligibility" :key="index">
           

              <div class="flex  space-x-4 bg-aqua p-6 ">

                <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
                <p class=" text-base lg:text-xl font-gramatika text-navy leading-relaxed">
                  {{ eligible.text }}</p>
              </div>
           
          </div>

          <p class="text-darknavy font-bold font-gramatika text-2xl ml-8 p-6">B. Shortlisted candidates from the initial assessment</p>

          <div v-for="(short, index) in shortlisted" :key="index">
           

            <div class="flex  space-x-4 bg-aqua p-6 ">

              <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
              <p class=" text-base lg:text-xl font-gramatika text-navy leading-relaxed">
                {{ short.text }}</p>
            </div>
         
        </div>

        </div>

      </div>

      <div>
        <div class=" space-x-4 lg:space-x-0 bg-aqua p-8 flex justify-between cursor-pointer"
          @click="toogleCriteria">
          <p class="font-rockinsoda text-navy text-5xl"> APPLICATION GUIDELINES</p>
          <i v-if="isCriteriaOpen" class=" text-navy fa fa-arrow-down  fa-3x" aria-hidden="true"></i>
          <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

        </div>
        <div v-if="isCriteriaOpen" class="bg-aqua">

          <div v-for="(crit, index) in criteria" :key="index">
              <div class="flex  space-x-4  p-6 ">
                <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
                <p class="text-base lg:text-xl text-navy font-gramatika leading-relaxed" v-html="crit.criteri">
</p>

              </div>
          

          </div>
        </div>
      </div>

      <div>
        <div class=" space-x-4 lg:space-x-0 bg-aqua p-8 flex justify-between cursor-pointer"
          @click="toogleLast">
          <p class="font-rockinsoda text-navy text-5xl"> ELIGIBILITY CRITERIA</p>
          <i v-if="isLastOpen" class=" text-navy fa fa-arrow-down  fa-3x" aria-hidden="true"></i>
          <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

        </div>
        <div v-if="isLastOpen" class="bg-aqua">
          <p class="text-darknavy  font-bold p-6 font-gramatika text-2xl ml-8">Applications will be assessed based on the following
            criteria.</p>

          <div v-for="(last, index) in lastSection" :key="index">
              <div class="flex  space-x-4  p-6 ">
                <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
                <p class=" text-base lg:text-xl text-navy font-gramatika  leading-relaxed">
                  {{ last.text }}</p>
              </div>
          
          </div>
        </div>
      </div>

    </div> 

         
      </div>
          

          <!-- Newsletter signup section -->
           <scrollButton/>

    <NewsLetter/>
  
    <!-- Footer section -->
    
  
    <FooterPage/>
</div>
      
  </div>
</template>


<script>
import NavIgnite from '@/components/NavIgnite.vue';
import FooterPage from '@/components/footerPage.vue';
import NewsLetter from '@/components/NewsLetter.vue';
import scrollButton from '@/components/scrollButton.vue';
export default{
  name:"HangaIgnite",
  components:{
          NavIgnite,
          FooterPage,
          NewsLetter,
          scrollButton
      },

      data(){

        return{

          eligibility: [
        {
          text: 'Completed application',
        },
        {
          text: 'Pitch deck in the template provided',
        },
        {
          text: 'Certificate(s) of incorporation/registration,',
        },
        {
          text: 'Income and expenditure statements for the period of operation',
        }
       
      ],

      shortlisted:[
        {
          text: 'Bank statement of the start up for the last 12 months',
        },
        {
          text: 'Applicable permit, licenses & clearances such as Clean Credit reference Bureau report RSSB & Tax Clearances to ensure Fully compliancy of all expected legal, statutory, regulatory, and financial requirements.',
        },
      
        {
          text: 'Shareholding documentation, identification documentation for shareholders, directors and senior management; and documentation of the ultimate beneficial owners.',
        }


      ],

      criteria: [

      {
     criteri: 'All applications must be submitted online: <a href="https://hanga.acceleratorapp.co/application/new?program=hanga-venture-ignite" target="_blank" class="underline">Use this link</a>'
    },

        {
          criteri: 'If you still have questions, please contact: [hanga.ventureignite@risa.gov.rw ]'
        },
        {
          criteri: 'Applications must be submitted in English.'
        },
        {
          criteri: 'Applicants are required to fill all the mandatory fields in the application form. Applications with not all filled-in fields or with required documents not provided are not eligible.'
        },
       
        {
          criteri: 'Only one application per startup is allowed per call. In case of multiple submissions only one application will be considered'
        },

      ],

      lastSection:[

      {
          text: 'The entity will be compliant with the definition of a startup within the understanding of this facility',
        },
      {
          text: 'Legal entity status  Be a legal entity, duly registered in Rwanda (the startups will be required to submit their certificate of incorporation/registration), acting as the sole applicant, directly responsible for the preparation and management of the grant application i.e. the implementing entity should be the one applying for the grant.',
        },
      {
          text: 'Startups should have been in operation (from the time of an official registration) for a maximum of five years at the time of application.',
        },
      {
          text: 'The startup should not have received a grant previously within the same tier within this facility. Startups that have successfully completed a tier 1 grant implementation can apply for tier 2, to support startups that have validated their business models to obtain additional funding for scaling.',
        },
      {
          text: 'Only one application per startup is allowed within the context of a specific call of proposals / cut off dates. Startups submitting multiple applications for one call for proposal will be disqualified.',
        },
      {
          text: 'The startups will need to have real identified viability potential by showing evidence that they are filling gaps in one or more of four dimensions  technical performance, market opportunity, team skills and dedication, and a plan for financial investment and return.',
        },
      {
          text: 'The startups must have a clear innovation, through the use of digital technology, that it is testing/developing.',
        },
      {
          text: 'Startups will be required to provide information in the application form, information to demonstrate the current setup of their financial management system guided by the minimum financial management requirements',
        },
      {
          text: 'Startups that do not meet the minimum financial management requirements are encouraged to include financial management support as part of the activities to be funded their application. This support will help the startup develop essential financial processes, implement accounting systems, and ensure compliance with legal and regulatory requirements.',
        },
      {
          text: "Startups applying for the Tier 1 grants must meet the following requirements:- The startups should not have significant revenue generation. Total revenue should be less than USD 10,000 for the period of operation. This should be supported by annual income and expenditure statements for the period of operation.- Startups should be at ideation stage with a clearly defined innovative concept. The concept should demonstrate significant potential for high growth. No fully developed product is required at this stage.",
        },
        {
          text: "Startups applying for the Tier 2 grants must meet the following criteria: Startups should have initial revenue generation but not exceeding USD 50,000 annual revenue for the period of operation. This should be supported by annual income and expenditure statements for the period of operation.- Startups have developed an MVP that includes essential features to meet early adopters' needs. The MVP should show clear potential for significant growth and scalability."
        },

      ],


     heroText: [
    "Empowering Rwandan Startups ",
    "with Early-Stage Funding"
      
    ],

    baseText:[
        'Hanga Venture Ignite fuels early-stage Rwandan startups ',
        'with funding to spark growth, drive innovation,',
        " and boost Rwanda's digital future.",

    ],

    isEligibilityOpen: false,
    isCriteriaOpen: false,
    isLastOpen:false,



    statsItems: [
      {  finalValue: 200, currentValue: 0, suffix: '+', description: 'Startups'},
      {  finalValue: 20, currentValue: 0, suffix: '+', description: 'Hanga SRH' },
      { finalValue: 190, currentValue: 0, suffix: '+', description: 'Hanga Hubs' },
    ],
    startX: 0,

        }

      },

      methods: {
  scrollLeft() {
    this.$refs.videoWrapper.scrollBy({ left: -500, behavior: 'smooth' });
  },
  scrollRight() {
    this.$refs.videoWrapper.scrollBy({ left: 500, behavior: 'smooth' });
  },

  toogleEligibility() {
      this.isEligibilityOpen = !this.isEligibilityOpen

    },

    toogleCriteria() {
      this.isCriteriaOpen = !this.isCriteriaOpen
    },

    toogleLast(){

      this.isLastOpen =!this.isLastOpen

    }

},


}

</script>

<style>

.slide-up {
transform: translateY(20px);
opacity: 0;
transition: all 2s ease-in-out;
}

.button-hover {
transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-hover:hover {
transform: scale(1.05); 
}


.scrollbar-hide {
scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
display: none; 
}



.hero-text {
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 0.8s ease forwards;
}

.hero-button {
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 0.8s ease forwards;
animation-delay: 1.5s;
}

.hero-image {
opacity: 0;
transform: translateY(20px);
animation: fadeInUp 0.8s ease forwards;
animation-delay: 2s;
}

@keyframes fadeInUp {
to {
  opacity: 1;
  transform: translateY(0);
}
}


@keyframes scroll {
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(-50%);
}


}

.fade-in {
opacity: 0;
transition: opacity 1.2s ease-in-out;
}

.fade-in-visible {
opacity: 1;
}

.slide-up-enter-active,
.slide-left-enter-active,
.slide-right-enter-active {
transition: all 0.7s ease-out;
}

.slide-up-enter-from {
opacity: 0;
transform: translateY(50px);
}

.slide-up-enter-to {
opacity: 1;
transform: translateY(0); /* Ensure Y-axis is reset to 0 */
}

.slide-left-enter-from {
opacity: 0;
transform: translateX(-50px);
}

.slide-left-enter-to {
opacity: 1;
transform: translateX(0); /* Reset X-axis */
}

.slide-right-enter-from {
opacity: 0;
transform: translateX(50px);
}

.slide-right-enter-to {
opacity: 1;
transform: translateX(0); /* Reset X-axis */
}
/* General Fade */
.fade-enter-active,
.fade-leave-active {
transition: opacity 0.6s ease-in-out;
}
.fade-enter, 
.fade-leave-to /* .fade-leave-active in <2.1.8 */ {
opacity: 0;
}

/* Fade and Slide Up */
.fade-slide-up-enter-active {
transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);}

.fade-slide-up-enter-from {
opacity: 0;
transform: translateY(50px);
}
.fade-slide-up-enter-to {
opacity: 1;
transform: translateY(0);
}

/* Fade and Slide Left */
.fade-slide-left-enter-active {
transition: all 1s cubic-bezier(0.42, 0, 0.58, 1); /* Even slower and more natural */
}
.fade-slide-left-enter-from {
opacity: 0;
transform: translateX(-80px);
}
.fade-slide-left-enter-to {
opacity: 1;
transform: translateX(0);
}

/* Fade and Slide Right */
.fade-slide-right-enter-active {
transition: all 1s cubic-bezier(0.42, 0, 0.58, 1); /* Even slower and more natural */
}
.fade-slide-right-enter-from {
opacity: 0;
transform: translateX(80px);
}
.fade-slide-right-enter-to {
opacity: 1;
transform: translateX(0);
}

/* Zoom In */
.zoom-enter-active {
transition: all 0.7s ease;
}
.zoom-enter-from {
opacity: 0;
transform: scale(0.9);
}
.zoom-enter-to {
opacity: 1;
transform: scale(1);
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
transition: opacity 1.1s ease-in-out, transform 1.1s ease-in-out;
}

.fade-slide-up-enter-from {
opacity: 0;
transform: translateY(50px);
}

.fade-slide-up-enter-to {
opacity: 1;
transform: translateY(0);
}

.fade-slide-up-leave-from {
opacity: 1;
transform: translateY(0);
}

.fade-slide-up-leave-to {
opacity: 0;
transform: translateY(80px);
}

</style>