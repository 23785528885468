  <template>
    <div class="h-screen overflow-x-hidden ">
      <NavInit />

      <!-- Hero text -->
      <div class=" ml-2 lg:ml-8 flex flex-col lg:flex-row mt-32 lg:mt-32 px-6 w-full lg:space-x-10 ">
        <!-- Text Section -->
        <div class="space-y-10">
          <div class="lg:text-left  space-y-4 w-2/3">
            <p class="text-4xl lg:text-8xl font-rockinsoda text-navy">REVOLUTIONALIZING HEALTHCARE AROUND THE GLOBE </p>
          </div>

          <!-- Description and Button Section -->
          <div class="text-justify mt-4 lg:mt-0 space-y-8 w-full lg:w-2/3">
            <p class="text-base lg:text-2xl font-gramatika text-navy">
              Exploring opportunities that exists for tech-enabled startups to solve key issues in SRH.
            </p>
            <!-- <div class="flex  lg:justify-start">
          <button class="p-2 px-4 font-gramatika bg-tangerine text-white hover:bg-tangerine-dark transition-all" ref="button">
            <a href="https://hanga.acceleratorapp.co/application/new?program=hanga-pitchfest-" class="mt-6">Start Your application</a>
        
          </button>
        </div> -->
          </div>
        </div>
      </div>

      <!-- Hero image -->
      <div class="relative w-full flex-shrink-0 mt-20  p-4 lg:p-10">
        <img src="../assets/nsrh/n-srh-2.jpg"
          class="w-full max-h-[800px] h-[60vh] sm:h-[100px] lg:h-[600px] object-cover">
      </div>

      
      <div
        class=" ml-2 lg:ml-10  flex flex-col lg:flex-row  w-full mt-12 lg:mt-12 lg:mt-32 px-2 p-0  lg:p-2 w-full lg:space-x-4 ">
        <!-- Text Section -->
        <div class="lg:text-left w-full lg:w-2/4 space-y- mt-12 lg:mt-20 ">
          <p class="text-3xl lg:text-6xl font-rockinsoda text-navy">FUNDING 20 TECH-ENABLED <br> HEALTH STARTUPS. </p>
        </div>

        <!-- Description and Button Section -->
        <div class="text-justify mt-4 lg:mt-0 space-y-8 w-full lg:w-2/4">
          <p class="text-base lg:text-lg font-gramatika text-navy mr-6 lg:mr-24">
            The Hanga Sexual & Reproductive Health (SRH) Program was conceived by the Government of Rwanda in 2022
            through HangaPitchFest to explore the opportunity that exists for technology enabled startups to solve key
            issues in SRH to accelerate better outcomes.
            This year, we are excited to work with key partners to scout, select, and provide technical and business
            support to 20 startups in Sub-Saharan Africa working on innovative tech-enabled solutions that are
            addressing challenges in ways that break the mold in SRH.
          </p>

        </div>
      </div>

      <div
        class="flex flex-wrap justify-center  lg:mr-4 sm:mr-0 lg:justify-between gap-y-20 gap-x-8 lg:gap-x-20  px-4 lg:px-10 mt-24 lg:mt-32">
        <div class="w-full sm:w-1/2 lg:w-1/4 fade-in space-y-4 text-center lg:text-left" ref="item1">
          <img src="../assets/1line.png" class="max-w-[150px] sm:max-w-[180px] lg:max-w-[200px] mx-auto lg:mx-0">
          <hr class="border-t border-gray-300">
          <div class="space-y-2">
            <p class="font-rockinsoda text-5xl text-navy">FUNDING</p>
            <p class="text-lightblue  text-lg font-gramatika mt-4 text-justify">Grant funding valued at <b>$10,000
                USD</b> with the scope to increase up to <b>$30,000 USD</b>
              for the highest performing start-ups in the program</p>
          </div>
        </div>

        <div class="w-full sm:w-1/2 lg:w-1/4 fade-in space-y-4 text-center lg:text-left" ref="item2">
          <img src="../assets/2line.png" class="max-w-[150px] sm:max-w-[180px] lg:max-w-[200px] mx-auto lg:mx-0">
          <hr class="border-t border-gray-300">
          <div class="space-y-2">
            <p class="font-rockinsoda text-5xl text-navy">LEARNING</p>

            <p class="text-lightblue text-lg font-gramatika mt-4"> <b>A 3 month</b>, cohort-based learning curriculum,
              covering entrepreneurship, evidence and SRH</p>

          </div>
        </div>

        <div class="w-full sm:w-1/2 lg:w-1/3 fade-in space-y-4 text-center lg:text-left" ref="item3">
          <img src="../assets/3line.png" class="max-w-[150px] sm:max-w-[180px] lg:max-w-[200px] mx-auto lg:mx-0">
          <hr class="border-t border-gray-300">
          <div class="space-y-2 ">
            <p class="font-rockinsoda text-5xl text-navy">BESPOKE SUPPORT</p>


            <p class="text-lightblue text-lg font-gramatika"> <b>Tailored support</b> from venture-building and SRH
              experts over a period of 9 months for eligible start-ups.</p>

          </div>
        </div>
      </div>

      <div class="overflow-hidden">
        <div class=" w-[193vw]  lg:w-full  mt-24 ml-4 mr-16 lg:mr-0 lg:ml-10">
          <div class="">
            <div class="flex space-x-10">
              <div class="w-full h-screen sm:h-auto lg:h-[65vh] flex flex-col">
                <!-- Image Section -->
                <div class="flex-grow h-full">
                  <img src="../assets/nsrh/n-srh-1.jpg" alt="" class="w-full h-full object-cover">
                </div>

                <!-- Text and Description Section -->
                <div class="h-auto mt-6 sm:mt-8 lg:mt-10 px-4">
                  <div class="space-y-4 sm:space-y-6">
                    <div class="text-center lg:text-left space-y-2">
                      <p class="text-2xl sm:text-3xl lg:text-4xl font-rockinsoda text-navy">BREAKING THE MOLD IN SRH?
                      </p>
                    </div>

                    <!-- Description and Button Section -->
                    <div class="text-justify mt-4 lg:mt-0 space-y-6 sm:space-y-8">
                      <p class="text-sm sm:text-base lg:text-xl font-gramatika text-navy">
                        We are working with key partners to scout, select, and provide technical and business support to
                        selected startups in Sub-Saharan Africa working on innovative tech-enabled solutions that are
                        addressing sexual reproductive health challenges.
                      </p>
                      <button class="p-2 font-gramatika text-xl sm:text-2xl text-navy transition-all" ref="button">
                        <div class="flex justify-center lg:justify-start space-x-4">
                          <!-- <a href="https://hanga.acceleratorapp.co/application/new?program=hanga-pitchfest-" target="_blank">APPLY</a> -->
                          <!-- <img src="../assets/Arrow 1.svg" alt="Arrow Icon"> -->
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full mt-40 ">
                <div class="h-[70vh] mr-20 flex flex-shrink-0 hidden lg:block">
                  <img src="../assets/nsrh/n-srh-4.jpg" class="w-full h-full object-cover">
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- next double section -->

        <div class=" w-[193vw] lg:w-full py-2 lg:py-20 -mt-10 lg:mt-32  ml-4 lg:ml-10">

          <div class="">
            <div class="flex space-x-10">
              <div class="flex flex-col w-full h-screen lg:h-[65vh]">
                <!-- Image Section -->
                <div class="w-full h-full">
                  <img :src="slides[currentSlide].image" alt="" class="w-full h-full object-cover">
                </div>

                <!-- Text and Description Section -->
                <div class="h-auto mt-6 sm:mt-8 lg:mt-10 px-4">
                  <div class="space-y-4 sm:space-y-6">
                    <div class="text-center lg:text-left space-y-2">
                      <p class="text-2xl sm:text-3xl lg:text-xl font-semibold font-gramatika text-navy">
                        {{ slides[currentSlide].title }}</p>
                    </div>

                    <!-- Description and Button Section -->
                    <div class="text-justify mt-4 lg:mt-0 space-y-6 sm:space-y-8">
                      <p class="text-sm sm:text-base lg:text-lg font-gramatika text-navy mb-10">
                        {{ slides[currentSlide].description }} </p>
                      <div class=" space-x-2 ">
                        <button @click="prevSlide"
                          class=" top-1/2 right-0 transform -translate-y-1/2 z-10 border-2  rounded-full p-4 bg-white text-navy focus:outline-none hover:bg-navy hover:text-white transition">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                          </svg>
                        </button>

                        <button @click="nextSlide"
                          class=" top-1/2 left-0 transform -translate-y-1/2 z-10 border-2  rounded-full p-4 bg-white text-navy focus:outline-none hover:bg-navy hover:text-white transition">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                            class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="w-full mt-40 ">
                <div class="h-[70vh] mr-20 flex flex-shrink-0 space-y-4 hidden lg:block">
                  <img src="../assets/nsrh/n-srh-6.jpg" class="w-full h-full object-cover">
                  <!-- <p class="text-sm sm:text-base lg:text-md font-gramatika text-navy mb-10">We know that this is not the
                    full extent of themes which constitute improving SRH outcomes,
                    and would love to hear from innovators with ideas beyond the mentioned themes.

                    We actively encourage youth and female-led start-ups innovating in SRH to apply. </p> -->
                </div>
              </div>

            </div>
          </div>
        </div>

      <!-- our timeline section -->
      <!-- <div class="ml-10 mt-24 lg:mt-60 ">
    <p class=" text-center lg:text-left text-6xl sm:text-3xl lg:text-5xl font-rockinsoda text-navy">Our Timeline</p>
   </div>
   <div class="relative mt-20 w-full px-4 lg:px-2 flex flex-col lg:flex-row justify-between items-start">
    <div class="w-full px-4 lg:px-10">
        <div class="flex flex-col lg:flex-row lg:space-x-10"> -->
      <!-- OCTOBER 20TH -->
      <!-- <div class="space-y-4">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">OCTOBER 20TH</p>
                <div class="flex justify-center lg:justify-start space-x-4">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/srh-rocket.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Applications Open</p>
                </div>
            </div> -->

      <!-- OCTOBER 31ST -->
      <!-- <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">OCTOBER 31ST</p>
                <div class="flex justify-center lg:justify-start space-x-4">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/info.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Info Session</p>
                </div>
            </div> -->

      <!-- NOVEMBER 15TH -->
      <!-- <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">NOVEMBER 15TH</p>
                <div class="flex justify-center lg:justify-start space-x-4">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/close.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Applications Close</p>
                </div>
            </div> -->

      <!-- NOVEMBER 20TH - 21ST -->
      <!-- <div class="space-y-4 mt-6 lg:mt-0">
                <p class="text-navy text-gramatika font-medium text-center lg:text-left">NOVEMBER 20TH - 21ST</p>
                <div class="flex justify-center lg:justify-start space-x-4">
                    <img src="../assets/Ellipse 11.svg" alt="">
                    <img src="../assets/navyline.svg" alt="">
                </div>
                <div class="bg-timelinebg flex items-center justify-center lg:justify-start pl-8 pr-10 py-4 rounded-md">
                    <img src="../assets/virtual.svg" alt="">
                    <p class="text-navy text-md font-medium font-gramatika">Virtual Pitching</p>
                </div>
            </div> -->
    </div>
    <!-- </div>
</div> -->
    <!-- send in your application -->
    <!-- <div class="flex items-center justify-center w-full mt-16 lg:mt-32 px-4">
  <div class="text-center w-full lg:w-2/3 space-y-6">
      <div>
          <p class="font-rockinsoda text-navy text-4xl lg:text-7xl leading-tight">SEND IN YOUR</p>
          <p class="font-rockinsoda text-navy text-4xl lg:text-7xl leading-tight">APPLICATIONS</p>
      </div>
      <div>
          <p class="text-gray-500 text-base lg:text-xl font-gramatika leading-relaxed">
              We know that this is not the full extent of themes which constitute improving SRH outcomes, and would love to hear from innovators with ideas beyond the mentioned themes.
          </p>
      </div>
      <button class="p-2 px-4 font-gramatika bg-tangerine text-white hover:bg-tangerine-dark transition-all">
          <a  class="mt-6">
              Start Your Application
          </a>
      </button>
  </div>
</div> -->


    <!-- Top 20 ventures -->
    <FilteredVentures class="-mt-32 lg:mt-0"/>

    <!-- <div class="mt-20 w-full space-y-10 p-10 ">
      <div>
        <div class="bg-lightTangerine p-8 flex justify-between cursor-pointer" @click="toogleEligibility">
          <p class="font-rockinsoda text-navy text-5xl"> ELIGIBILITY</p>
          <i v-if="isEligibilityOpen" class=" text-navy fa fa-arrow-down  fa-3x" aria-hidden="true"></i>
          <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

        </div>
        <div v-if="isEligibilityOpen">
          <div v-for="(eligible, index) in eligibility" :key="index">
            <transition name="fade-slide-down" appear>

              <div class="flex  space-x-4 bg-lightTangerine p-6 ">
                <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
                <p class="text-gray-500 text-base lg:text-2xl font-gramatika text-navy leading-relaxed">
                  {{ eligible.text }}</p>
              </div>
            </transition>
          </div>
        </div>

      </div>

      <div>
        <div class=" space-x-4 lg:space-x-0 bg-lightTangerine p-8 flex justify-between cursor-pointer"
          @click="toogleCriteria">
          <p class="font-rockinsoda text-navy text-5xl"> SELECTION CRITERIA</p>
          <i v-if="isCriteriaOpen" class=" text-navy fa fa-arrow-down  fa-3x" aria-hidden="true"></i>
          <i v-else class=" text-navy fa fa-arrow-right  fa-3x" aria-hidden="true"></i>

        </div>
        <div v-if="isCriteriaOpen" class="bg-lightTangerine">
          <p class="text-navy font-gramatika text-xl ml-8">Applications will be assessed based on the following
            criteria.</p>

          <div v-for="(crit, index) in criteria" :key="index">
            <transition name="fade-slide-down" appear>
              <div class="flex  space-x-4  p-6 ">
                <i class="fa fa-check text-navy  fa-2x" aria-hidden="true"></i>
                <p class="text-gray-500 text-base lg:text-2xl text-navy font-gramatika  leading-relaxed">
                  {{ crit.criteri }}</p>
              </div>
            </transition>

          </div>
        </div>
      </div>

    </div> -->



    <!-- Hanga SRH Network -->


    <div>
      <div
        class="ml-4 mr-4 xl:mt-4 2xl:-mt-12 mlg:mr-10 md:ml-12 flex flex-col md:flex-row lg:space-x-16 items-start justify-between">
        <div class="flex-1  mt-12 xl:mt-2 2xl:mt-60 md:mt-8 max-w-full md:max-w-[50%]">
          <div class="space-y-2  md:mt-28 ">
            <p class="text-4xl md:text-8xl font-rockinsoda text-navy">THE HANGA SRH</p>
            <p class="text-4xl md:text-8xl font-rockinsoda text-navy">NETWORK</p>
          </div>

          <div class="mt-10">

            <div class="text-justify space-y-12 w-full">
              <p class="text-navy font-gramatika text-lg md:text-md">
                We are supporting investment in, and support for SRH ventures across Sub-Saharan Africa.
              </p>

              <p class="text-navy font-gramatika text-lg md:text-md">
                The Hanga SRH Network is part ofbThe Hanga, Sexual & Reproductive Health (SRH) Program alongside
                supporting 20 brilliant SRH focused ventures over the course of 2023,we are growing a wider network of
                SRH ecosystem builders including Governments, Venture Capital Firms and NGOs.
              </p>

              <p class="text-navy font-gramatika text-lg md:text-md">
                Some of the organizations we have engaged with in our ecosystem, such as Renew Capital and Kumwe Hub by
                Save the Children, are bringing their expertise and experience to the opportunity of advancing SRH
                across Sub-Saharan Africa.
              </p>
              <p class="text-navy font-gramatika text-lg md:text-md">
                Our mission is to create AN ACTIVE SRH ECOSYSTEM armed with evidence and connections who have the
                ability to collectively grow the market for SRH initiatives and increase their impact in Africa.
              </p>

              <p class="text-navy font-gramatika text-lg md:text-md">
                By convening SRH pioneers in discussion and generously showcasing the work and learnings of sector
                actors, we believe we can expand the impact of SRH innovation across Sub-Saharan Africa.
              </p>
            </div>

          </div>
        </div>

        <div
          class="flex-1 h-[300px] sm:mt-10 md:h-auto flex-shrink-0 md:mt-32 xl:mt-36 2xl:mt-52 max-w-full md:max-w-[50%]">
          <img src="../assets/nsrh/n-srh-3.jpg" class="w-full h-[50vh] mt-10 object-cover">
          <img src="../assets/nsrh/n-srh-5.jpg" class="w-full h-[50vh] mt-10 object-cover">
        </div>

      </div>
    </div>

    <!-- Join Network -->

    <div class="relative h-auto min-h-[600px]">
      <div class="mt-20 pb-20 py-16 sm:mt-32 bg-cover h-full"
        :style="{ backgroundImage: `url(${require('@/assets/notched.svg')})` }">
        <div class="flex">

          <!-- Text Section with fade-in animation -->
          <div class=" mt-12 sm:mt-32 lg:mt-20 ml-8 mr-8 sm:ml-8 md:ml-12 p-4 sm:p-6 w-full">
            <div>
              <h2
                class="text-5xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-7xl 3xl:text-8xl text-white font-rockinsoda mb-4">
                Join the Network
              </h2>
            </div>

            <div class="mt-10">

              <div class="text-justify space-y-12 w-full">
                <p class="text-white font-gramatika text-lg md:text-md sm:text-2xl md:text-3xl lg:text-2xl ">
                  Our Network is an inclusive community of people passionate about improving outcomes in SRH across
                  Sub-Saharan Africa.
                </p>

                <p class="text-white font-gramatika text-lg md:text-md sm:text-2xl md:text-3xl lg:text-2xl ">
                  It is a space for everyone to learn about and support promising SRH initiatives, share their
                  knowledge,
                  create connections and collaborations and work together to grow the market for SRH initiatives across
                  Sub-Saharan Africa.
                </p>

                <p class="text-white font-gramatika text-lg md:text-md sm:text-2xl md:text-3xl lg:text-2xl ">
                  Within this we have two exclusive roles for network members to participate and contribute in more
                  depth
                  to the Hanga SRH Programme, mainly supporting the 20 SRH ventures currently receiving support to test
                  and grow their work.
                </p>

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>


    <div class="w-full flex flex-col lg:flex-row  mt-16 p-4 lg:p-10 mb-10">
      <!-- <div class="space-y-4 text-center lg:text-left">
        <p class="font-rockinsoda text-navy text-2xl sm:text-3xl">HAVE A QUESTION ABOUT THE</p>
        <p class="font-rockinsoda text-navy text-2xl sm:text-3xl">PROGRAM?</p>
        <button class="font-gramatika text-xl sm:text-2xl text-navy transition-all" ref="button">
          <div class="flex justify-center lg:justify-start space-x-4">
            <a href="">Join info session</a>
            <img src="../assets/Arrow 1.svg" alt="Arrow Icon">
          </div>
        </button>
      </div> -->

      <div class="space-y-4 mt-8 mr-0 lg:mr-20 lg:mt-0 text-center lg:text-left">
        <p class="font-gramatika text-navy text-lg sm:text-xl">Email us at:</p>
        <p class="font-gramatika text-navy text-lg sm:text-xl underline">srhprogram@hangapitchfest.rw</p>
        <p class="font-gramatika text-navy text-lg sm:text-xl">visit:</p>
        <router-link to="/srh-faq">
        <a href="" class="font-gramatika text-navy text-lg sm:text-xl">Hanga SRH FAQ</a>
      </router-link>
      </div>
    </div>

    <scrollButton />
    <FooterPage />

    </div>


  </template>
<script>
import NavInit from '@/components/NavInit.vue';
import FooterPage from '@/components/footerPage.vue';
import scrollButton from '@/components/scrollButton.vue';
import FilteredVentures from '@/components/FilteredVentures.vue';
export default {
  name: "HangaSrh",
  components: {
    NavInit,
    FooterPage,
    scrollButton,
    FilteredVentures
  },

  data() {

    return {
      eligibility: [
        {
          text: 'Must be the Founder / Co-founder and/or hold primary decision-making power in the start-up',
        },
        {
          text: 'Be digitally literate with consistent access to a laptop and  internet',
        },
        {
          text: 'Must be operating in any country in Sub-Saharan Africa',
        },
        {
          text: 'Must be developing a product or venture which contributes towards a sexual and reproductive health (SRH) theme in an innovative and pioneering way',
        },
        {
          text: 'Must be registered by the relevant authorities in the country of operation',
        },
        {
          text: 'The start-up should not have raised any venture capital (except angel investors, family, and friends). Start-ups that may have received grant funding from other incubator programs or hackathons are eligible.',
        },

      ],

      criteria: [

        {
          criteri: 'Impact- does the start-up have potential to make meaningful change in SRH outcomes?'
        },
        {
          criteri: 'Mindset is the applicant curious and collaborative? Are they driven by the problem with a willingness to learn and grow with their idea?'
        },
        {
          criteri: 'Breaking the mold is the idea a pioneering way of tackling an SRH issue or is it  tackling a traditionally underinvested issue?'
        },
        {
          criteri: 'Sustainability does the idea have the potential to be sustainable at scale?'
        },

      ],

      slides: [
        {
          image: require('../assets/nsrh/5.jpg'),
          title: "Join Us If you are / 05",
          description: "Improving maternal health beyond the access to antenatal care for pregnant women.",
        },
        {
          image: require('../assets/nsrh/4.jpg'),
          title: "Join Us If you are / 04",
          description: "Tackling unsafe abortion by removing the barriers to accessing safe abortion",
        },
        {
          image: require('../assets/nsrh/3.jpg'),
          title: "Join Us If you are / 03",
          description: "Targeting young people (10-24)with youth-friendly SRH information and services. ",
        },
        {
          image: require('../assets/nsrh/2.jpeg'),
          title: "Join Us If you are / 02",
          description: "Tackling gender based violence including sexual violence. ",
        },
        {
          image: require('../assets/nsrh/1.jpg'),
          title: "Join Us If you are / 01",
          description: "Meeting the need for contraception including education about and access to contraception.",
        },

      ],

      isEligibilityOpen: false,
      isCriteriaOpen: false,
      currentSlide: 0

    }
  },

  methods: {

    toogleEligibility() {
      this.isEligibilityOpen = !this.isEligibilityOpen

    },

    toogleCriteria() {
      this.isCriteriaOpen = !this.isCriteriaOpen
    },

    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    },

  },



  mounted() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up-visible');
          entry.target.classList.add('zoom-in-visible');
          entry.target.classList.add('fade-in-visible');

        }
      })
    });


    const targetId = this.$route.hash.substring(1);
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // Observe all elements with the zoom-in class
    const elements = this.$refs;
    for (let key in elements) {
      if (elements[key]) {
        observer.observe(elements[key]);
      }
    }
    // Observe all elements with the fade-in class
    const items = [this.$refs.item1, this.$refs.item2, this.$refs.item3];
    items.forEach(item => {
      observer.observe(item);

    });
  }
}

</script>

<style>
.slide-up {
  transform: translateY(20px);
  opacity: 0;
  transition: all 2s ease-in-out;
}

.slide-up-visible {
  transform: translateY(0);
  opacity: 1;
}

.zoom-in {
  transform: scale(0.95);
  opacity: 0;
  transition: transform 1s ease-in-out, opacity 0.90s ease-in-out;
}

.zoom-in-visible {
  transform: scale(1);
  opacity: 1;
}

.button-hover {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button-hover:hover {
  transform: scale(1.05);
}

.fade-in {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
}

.fade-in:nth-child(1) {
  transition-delay: 0.3s;
}

.fade-in:nth-child(2) {
  transition-delay: 0.6s;
}

.fade-in:nth-child(3) {
  transition-delay: 0.9s;
}

/* General Fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease-in-out;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

/* Fade and Slide Up */
.fade-slide-down-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
}

.fade-slide-down-enter-from {
  opacity: 0;
  transform: translateY(-20px);
  /* Start above */
}

.fade-slide-down-enter-to {
  opacity: 1;
  transform: translateY(0);
  /* End at original position */
}

/* Fade and Slide Left */
.fade-slide-left-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
  /* Even slower and more natural */
}

.fade-slide-left-enter-from {
  opacity: 0;
  transform: translateX(-80px);
}

.fade-slide-left-enter-to {
  opacity: 1;
  transform: translateX(0);
}

/* Fade and Slide Right */
.fade-slide-right-enter-active {
  transition: all 1s cubic-bezier(0.42, 0, 0.58, 1);
  /* Even slower and more natural */
}

.fade-slide-right-enter-from {
  opacity: 0;
  transform: translateX(80px);
}

.fade-slide-right-enter-to {
  opacity: 1;
  transform: translateX(0);
}

/* Zoom In */
.zoom-enter-active {
  transition: all 0.7s ease;
}

.zoom-enter-from {
  opacity: 0;
  transform: scale(0.9);
}

.zoom-enter-to {
  opacity: 1;
  transform: scale(1);
}

.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 1.1s ease-in-out, transform 1.1s ease-in-out;
}

.fade-slide-up-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade-slide-up-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-up-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-up-leave-to {
  opacity: 0;
  transform: translateY(80px);
}
</style>