<template>
    <div class="min-h-screen">
      <div class="relative h-screen min-h-[500px] max-h-[570px] sm:max-h-[800px] lg:max-h-[950px] xl:max-h-[1000px]">
        <div class="absolute inset-0 bg-black opacity-60"></div>
        <img src="../assets/faq.jpeg" alt="Background" class="h-full w-full object-cover" />
        
        <NavIgnite class="absolute top-0 left-0 w-full" />
        <div class="font-rockinsoda absolute text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl top-2/4 left-4 sm:left-8 md:left-12 lg:left-16 xl:left-20 text-center sm:text-left" ref="text">
          <p>FREQUENTLY ASKED</p>
          <p>QUESTIONS?</p>
        </div>
      </div>
  
      <!-- Middle section -->
      <div class="w-full  mt-20 md:mt-10">
        <!-- First row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12">
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Who can apply for the grant?</p>
            <p class="font-gramatika text-gray-500">The grant is open to Rwandan-registered startups that meet the eligibility criteria for either Tier 1 (Ignite) or Tier 2 (Ignite+). To apply, your startup must be based in Rwanda and registered with the Rwanda Development Board (RDB) for a maximum of 5 years. Each startup should be innovation-driven, with the potential for high growth. If your startup is at the ideation stage, you may apply for Tier 1. If your startup has a Minimum Viable Product (MVP), you may apply for Tier 2.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What is the difference between Tier 1 and Tier 2 grants?</p>
            <p class="font-gramatika text-gray-500">Tier 1 (Ignite): This grant is for startups in the ideation phase, meaning those that are still developing and validating their initial ideas and concepts. These startups should not have generated more than USD 10,000 in revenue during that period.

              Tier 2 (Ignite+): This grant is for startups that have developed a Minimum Viable Product (MVP) and are seeking to refine their value proposition, scale, and enter new markets.
               These startups should have a maximum of USD 50,000 in annual revenue in any of the years of operation.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
        </div>
  
        <!-- Second row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12">

          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What are the minimum financial management requirements for the startups?</p>
            <p class="font-gramatika text-gray-500">Startups will be required to provide information in the application form, information to demonstrate the current setup of their financial management system guided by the minimum financial management requirements annexed in this document (Annex 1).
              Startups that do not meet the minimum financial management requirements are encouraged to include financial management support as part of the activities to be funded their application. This support will help the startup develop essential financial processes, implement accounting systems, and ensure compliance with legal and regulatory requirements.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>


          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Are startups required to report on their progress?</p>
            <p class="font-gramatika text-gray-500">Yes, grant recipients must provide regular reports on their progress, financial performance, and how the grant funds have been used. The facility will monitor progress through periodic reviews to ensure that the funds are used effectively.
            The details will be clarified in the agreements they sign.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
        
        </div>
  
        <!-- Third row -->
        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12  ">
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Can a startup apply for both Tier 1 and Tier 2?</p>
            <p class="font-gramatika text-gray-500">No, a startup can only apply for one tier during each call for applications. However, if you successfully complete a Tier 1 grant and meet the necessary criteria, you may later apply for a Tier 2 grant in future rounds. 
              This allows startups to progress through the funding tiers as they grow.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
  
        
        
  
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What is the duration of the grant activities?</p>
            <p class="font-gramatika text-gray-500">All grant activities must be completed within 12 months from the date the grant is awarded. Startups are expected to plan and execute their activities efficiently,
               reaching the set milestones within the specified time frame.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>

        </div>

        <!-- 4th row  -->

        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12  ">
         
          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">Can a startup apply multiple times in one call for applications?</p>
            <p class="font-gramatika text-gray-500">Actually companies are allowed to apply multiple times, there will be no deadline so companies are allowed to apply anytime until the program will be closed in 2026. 
              We will be evaluating the companies continuously.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>

          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What sectors are eligible?</p>
            <p class="font-gramatika text-gray-500">The grant is sector-agnostic, open to innovation-driven startups from all industries.</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>

        </div>

        <!-- 5th row  -->

        <div class="flex flex-col sm:flex-row justify-between p-5 sm:p-10 space-y-12 sm:space-y-0 sm:space-x-12  ">
         
        

          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">How will the grant funds be disbursed?</p>
            <p class="font-gramatika text-gray-500">Disbursements shall be made in tranches by BRD, against the approved disbursement schedules, agreed milestones and supporting documentation. 
              Supporting documentation will be submitted by the beneficiaries as part of the reporting on funds utilization</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>

          <div class="space-y-6">
            <p class="font-gramatika text-navy text-2xl font-medium">What size grants are available?</p>
            <p class="font-gramatika text-gray-500">Tier 1 (Ignite): Grants of USD 20,000 - USD 50,000 are available for startups in the ideation stage.
              Tier 2 (Ignite+): Grants of USD 50,000 - USD 100,000 are available for startups with an MVP aiming to scale their business</p>
            <hr class="border border-t-2 border-gray-200 w-auto" />
          </div>
         

        </div>


      </div>
      <FooterPage  class="mt-20"/>
    </div>
  </template>

<script>
import NavIgnite from '@/components/NavIgnite.vue';
import FooterPage from '@/components/footerPage.vue';

export default{
    name:"IgniteFaq",
    components:{
        NavIgnite,
        FooterPage
    }
}
</script>